import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import landingParamsSelector from 'src/redux/selectors/landingParams';
import createRequest from 'src/service/apiService';
import endpoints from 'src/utils/endpoints';
import { setUserInfo } from 'src/redux/slices/userInfo';
import {
  setParams,
  hasErrorFetchingUIConfig,
  setUIConfig,
  setMandatoryFields,
} from 'src/redux/slices/landingParams';
import { KeyValueToObjectFormatter } from 'src/utils/commonUtils';
import { cloneDeep } from 'lodash';
import flattenArray from 'src/utils/flattenArray';
import { useAuthContext } from '../../auth/useAuthContext';

type LandingWrapperProps = {
  children: React.ReactNode;
};
const LandingWrapper = ({ children }: LandingWrapperProps) => {
  const { user, getUIConfig } = useAuthContext();
  const dispatch = useDispatch();
  const commonQuery = useSelector(landingParamsSelector.getParams);
  const { clientCode, programIdentifier, serviceName } = useParams();
  const signupConfig = useSelector(landingParamsSelector.fetchingUIConfig);
  
  useEffect(() => {
    if (Object.keys(signupConfig).length) {
      const tmp = cloneDeep(signupConfig?.sections || []);
      const result: any = flattenArray(tmp[0]?.rows, 'columns').splice(tmp[0]?.rows?.length);
      dispatch(setMandatoryFields(result));
    }
  }, [dispatch, signupConfig]);

  const commonQueryParams = {
    clientCode: user?.clientCode,
    programIdentifier: ((Array.isArray(user?.programs)) && (user?.programs[0]?.key) || null)
  };

  function setQueryParams(): any {
    dispatch(setParams(commonQueryParams));
  }

  /*  eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setQueryParams();
  }, [clientCode, programIdentifier, serviceName]);

  const getUserDetails = async () => {
    try {
      const config: any = {
        url: endpoints.getLoggedinUserProfile,
        data: { ...commonQuery, userName: user?.displayName },
        method: 'POST',
      };
      const response = await createRequest(config);
      if (response.status === 200) {
        dispatch(setUserInfo(KeyValueToObjectFormatter(response.data?.responseDetail, 'obj')));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlerDispatch = (data: any) => {
    dispatch(setUIConfig(data));
  };

  useEffect(() => {
    getUserDetails();
    getUIConfig(
      {
        ...commonQuery,
        clientCode: user?.clientCode,
        programIdentifier: ((Array.isArray(user?.programs)) && (user?.programs[0]?.key) || null),
        serviceName: 'MANAGE_CUSTOMER_USER',
        memberType: 'Primary',
        scheme: '',
      },
      handlerDispatch,
      hasErrorFetchingUIConfig
    );
  }, []);

  return <>{children}</>;
};

export default LandingWrapper;
