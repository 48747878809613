import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import constants from 'src/utils/constants';
import landingParamsSelector from 'src/redux/selectors/landingParams';
import localStorageAvailable from 'src/utils/localStorageAvailable';
import { useSettingsContext } from 'src/components/settings';
// routes
import { PATH_AUTH, PATH_DASHBOARD } from '../../routes/paths';
// auth
import { useAuthContext } from '../../auth/useAuthContext';
// components
import Iconify from '../../components/iconify';
import FormProvider, { RHFTextField } from '../../components/hook-form';

// ----------------------------------------------------------------------

type FormValuesProps = {
  userName: string;
  password: string;
  afterSubmit?: string;
};

function AuthLoginForm() {
  const { login } = useAuthContext();
  const { onToggleMode, onChangeColorPresets } = useSettingsContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const commonQuery = useSelector(landingParamsSelector.getParams);
  const storageAvailable = localStorageAvailable();
  const user: any = storageAvailable
    ? JSON?.parse(localStorage?.getItem('userDetails') || '{}')
    : {};

  const LoginSchema = Yup.object().shape({
    userName: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
  });
  // systemadmin1@m81labs.com
  // 8123456784
  // const defaultValues = {
  //   userName: '',
  //   password: '',
  // };

  const defaultValues = {
    userName: '',
    password: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  const routerMapper = () => {
    switch (user.role) {
      case 'CustomerUser':
        navigate(PATH_DASHBOARD.general.banking);
        break;
      default:
        navigate(PATH_DASHBOARD.general.app);
        break;
    }
  };
  const onSubmit = async (data: FormValuesProps) => {
    const newcommonQuery = {
      ...commonQuery,
      clientCode: null,
      programIdentifier: null,
    };
    try {
      const response: any = await login(data.userName, data.password, newcommonQuery);
      if (response.responseCode === 200) {
        
        if(Array.isArray(response?.clientConfig) && response?.clientConfig[0]?.value === 'light'){
          // window.location.reload();
          onToggleMode();
        }
        if(Array.isArray(response?.clientConfig) && response?.clientConfig[1]?.value){
          onChangeColorPresets(response?.clientConfig[1]?.value);
        }
        if (pathname.includes('/login')) {
          routerMapper();
        }
      } else {
        setError('afterSubmit', {
          ...response,
          message: response?.responseMessage || response,
        });
      }
    } catch (error) {
      setError('afterSubmit', {
        ...error,
        message: error?.responseMessage || error,
      });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
        <RHFTextField name="userName" label="Email address" />
        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      <Stack alignItems="flex-end" sx={{ my: 2 }}>
        <Link
          component={RouterLink}
          to={PATH_AUTH.resetPassword}
          variant="body2"
          color="inherit"
          underline="none"
        >
          {constants.login.forgot}
        </Link>
      </Stack>
      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitSuccessful || isSubmitting}
        sx={{
          bgcolor: 'text.primary',
          color: (theme: any) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
          '&:hover': {
            bgcolor: 'text.primary',
            color: (theme: any) => (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'),
          },
        }}
      >
        {constants.login.btn1}
      </LoadingButton>
      <Divider
        sx={{
          my: 2.5,
          typography: 'overline',
          color: 'text.disabled',
          '&::before, ::after': {
            borderTopStyle: 'dashed',
          },
        }}
      >
        OR
      </Divider>
      <Stack alignItems="flex-end" sx={{ my: 2 }}>
        <LoadingButton
          fullWidth
          color="inherit"
          size="large"
          variant="outlined"
          onClick={() => navigate(PATH_AUTH.loginWithOtp)}
        >
          {constants.login.btn2}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}

export default React.memo(AuthLoginForm);