// @mui
import { List, Stack } from '@mui/material';
import localStorageAvailable from 'src/utils/localStorageAvailable';
// locales
import { useLocales } from '../../../locales';
//
import { NavSectionProps } from '../types';
import { StyledSubheader } from './styles';
import NavList from './NavList';

// ----------------------------------------------------------------------

export default function NavSectionVertical({ data, sx, ...other }: NavSectionProps) {
  const { translate } = useLocales();
  const storageAvailable = localStorageAvailable();
  const user: any = storageAvailable ? JSON.parse(localStorage.getItem('userDetails') || '') : {};
  return (
    <Stack sx={sx} {...other}>
      {/* const key = group.subheader || group.items[0].title; */}
      {data.map((group, index: number) => (
        <List disablePadding sx={{ px: 2 }} key={index}>
          {/* {group.subheader && (
            <StyledSubheader disableSticky>{`${translate(group.subheader)}`}</StyledSubheader>
          )} */}
          {/* user?.permissions.includes(list.title) && list */}
          {group.items
            .filter((list: any) => user?.permissions.includes(list.title) && list)
            .map((list) => (
              <NavList
                key={list.title + list.path}
                data={list}
                depth={1}
                hasChild={!!list.children}
                permissions={user?.permissions}
              />
            ))}
        </List>
      ))}
    </Stack>
  );
}